<template>
  <div>
    <Breadcrumbs :main="$tc('models.admin.entity', 1)" :title="$t('actions.invite')"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form :fields="fields" :submitButton="submitButton" @submitted="handleSubmit" />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';

export default {
  name: 'Invite',
  data() {
    return {
      modelName: 'Admin',
      fields: {
        name: {
          type: 'text',
          key: 'name',
          value: '',
          label: this.$t('models.user.attributes.name'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        role: {
          type: 'select',
          key: 'role',
          value: '',
          label: this.$t('models.user.attributes.role'),
          selectOptions: ['Super', 'Regular'],
          classes: 'col-12 py-0',
          required: true,
          valid: false
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          classes: 'col-12 py-0',
          required: true,
          valid: false
        }
      }
    };
  },
  methods: {
    ...mapActions(['inviteAdmins']),
    handleSubmit(data) {
      data['role'] = data['role'].toLocaleLowerCase();
      this.inviteAdmins(data);
    }
  },
  mixins: [FormsMixin]
};
</script>
